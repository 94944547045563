<template>
  <div class="login">
    <h1>{{ this.$t("auth.header_login") }}</h1>
    <div class="section">
      <label for="email">{{ $t("auth.title_email") }}</label>
      <input
        id="email"
        type="email"
        placeholder="example@email.com"
        v-model="login_email"
        v-on:keyup.enter="process_enter"
      />
      <p class="error">{{ error_email }}</p>
    </div>
    <div class="section">
      <label for="password">{{ $t("auth.title_password") }}</label>
      <input
        id="password"
        type="password"
        v-model="login_password"
        @keyup.enter="process_enter"
      />
      <p class="error">{{ error_password }}</p>
    </div>
    <div class="section text-right">
      <router-link to="/auth/find/">
        {{ $t("auth.link_find_password") }}
      </router-link>
    </div>

    <div class="section">
      <button
        class="button red"
        id="login_button"
        :class="{ inactive: !is_active_button }"
        @click="try_login"
      >
        {{ $t("auth.button_login") }}
      </button>
      <p class="error">{{ error_login }}</p>
    </div>

    <div class="section sns text-center">
      <button class="button outline compact google" @click="this.google_login">
        {{ this.$t("auth.button_google") }}
      </button>
    </div>

    <div class="section text-center">
      <!-- <button class="button" @click="this.$router.push('/auth/join')">
        {{ $t("auth.button_join") }}
      </button> -->
      <router-link to="/auth/join">
        {{ $t("auth.link_go_to_join") }}
      </router-link>
    </div>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
import { googleTokenLogin } from "vue3-google-login";
export default {
  name: "Login",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.login"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.login"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      login_email: "",
      login_password: "",
      is_active_button: false,
      error_email: "",
      error_password: "",
      error_login: "",

      return_url: "",
    };
  },
  mounted() {
    // 이미 로그인 중이면...
    if (this.storage.is_logged_in()) {
      let self = this;

      this.$swal
        .fire({
          text: self.$t("auth.alert_already_logged_in"),
          icon: "error",
        })
        .then(() => {
          self.$router.push("/");
        });
    }

    // 쿼리스트링 가져오기
    if (
      typeof this.$route.query.return_url != "undefined" &&
      this.$route.query.return_url != "" &&
      !this.$route.query.return_url.startsWith("http") &&
      !this.$route.query.return_url.startsWith("//")
    ) {
      this.return_url = decodeURIComponent(this.$route.query.return_url);
    }
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  watch: {
    login_email: function () {
      this.validate_email();
      this.check_button();
    },
    login_password: function () {
      this.validate_password();
      this.check_button();
    },
  },
  methods: {
    // 이메일 검증
    validate_email: function () {
      if (this.common.validate_email(this.login_email)) {
        this.error_email = "";
      } else {
        this.error_email = this.$t("auth.error_invalid_email"); // 잘못된 이메일 형식입니다.
      }
    },

    // 비밀번호 검증
    validate_password: function () {
      if (this.login_password.trim() == "") {
        this.error_password = this.$t("auth.error_enter_password"); // 비밀번호를 입력해주세요.
      } else {
        this.error_password = "";
      }
    },

    // 버튼 활성화 확인
    check_button: function () {
      if (
        this.common.validate_email(this.login_email) &&
        this.login_password.trim() != ""
      ) {
        this.is_active_button = true;
      } else {
        this.is_active_button = false;
      }

      this.error_login = "";
    },

    process_enter: function () {
      this.try_login();
    },

    try_login: function () {
      if (this.processing || !this.is_active_button) return;

      var self = this;
      self.process_queue.push(1);

      self.error_login = "";

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/auth/login",
          {
            email: this.login_email,
            password: this.login_password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.data.result == "OK") {
            // 로그인 성공 후 처리
            self.login_success_callback(response);
          } else if (response.data.result == "ERR_NO_USER") {
            // NO USER
            self.error_login = self.$t("auth.error_login_no_user");
          } else if (response.data.result == "ERR_ALREADY_LOGIN") {
            // ERR_ALREADY_LOGIN
            self.$swal
              .fire({
                text: self.$t("auth.alert_already_logged_in"),
                icon: "error",
              })
              .then(() => {
                self.$router.push("/");
              });
          } else if (response.data.result == "ERR_WRONG_COUNTRY") {
            // 국가 정보 가져오기
            let joined_country = response.data.country;
            let joined_country_name =
              self.common.get_country_name_by_code3(joined_country);

            let country_code2 = self.common
              .get_country_code2_by_code3(joined_country)
              .toLowerCase();

            // 코드2가 존재하면...
            if (country_code2 != "") {
              // 이동할 URL 생성하기
              let country_url = "https://";
              if (country_code2 == "kr") {
                country_url += "weknowthem.com/auth/login";
              } else {
                country_url += country_code2 + ".weknowthem.com/auth/login";
              }

              let move_message_html = self.$t("auth.error_wrong_country_ask1");
              move_message_html += "<p><b>" + joined_country_name + "</b><p>";
              move_message_html += self.$t("auth.error_wrong_country_ask2");

              // 물어보고 국가 서비스 이동
              self.$swal
                .fire({
                  html: move_message_html,
                  icon: "error",
                  showCancelButton: true,
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    location.href = country_url;
                  }
                });
            } else {
              // 일반 국가 에러 메시지
              self.$swal.fire({
                text: self.$t("auth.error_wrong_country"),
                icon: "error",
              });
            }
          } else {
            // 일반 오류 메시지
            self.error_login = self.$t("common.error_occur");
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        });
    }, //-- try_login

    // 저장소 장바구니 데이터를 DB에 새로 추가
    add_user_cart_server: function () {
      let self = this;

      return new Promise((resolve, reject) => {
        // 저장소에 장바구니 데이터 추가
        let user_cart = self.storage.get_user_cart();

        // 장바구니 데이터 만들기
        let cart_data = [];
        for (let i = 0; i < user_cart.length; i++) {
          let cart = {
            cart_id: user_cart[i].cart_id,
            product_id: user_cart[i].product_id,
            product_option: user_cart[i].product_option,
            product_count: user_cart[i].product_count,
            combination_id: user_cart[i].product_option_combination_id,
            cart_timestamp: user_cart[i].cart_timestamp,
          };

          cart_data.push(cart);
        }

        self.axios
          .post(
            process.env.VUE_APP_API_URL + "/cart/add",
            {
              user_id: self.storage.get_user_id(),
              cart_data: cart_data,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(function (response) {
            let result = response.data.result;

            if (result == "OK") {
              self.storage.clear_user_cart(); // 저장소 장바구니 비우기
              resolve(result);
            } else if (result == "ERR_NO_PARAM") {
              reject(result);
            }
          }); //-- axios then
      }); //-- Promise
    },

    // 구글로 로그인
    google_login: function () {
      let self = this;
      googleTokenLogin().then((response) => {
        let access_token = response.access_token;

        self.axios
          .post(
            process.env.VUE_APP_API_URL + "/auth/google",
            {
              access_token: access_token,
              user_country: self.common.get_country_code_from_url(),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(function (response) {
            // 로그인 성공인 경우...
            if (response.data.result == "OK") {
              self.login_success_callback(response);
            } else if (response.data.result == "ERR_ALREADY_LOGIN") {
              // ERR_ALREADY_LOGIN
              self.$swal
                .fire({
                  text: self.$t("auth.alert_already_logged_in"),
                  icon: "error",
                })
                .then(() => {
                  if (self.return_url != "") {
                    location.href = self.return_url;
                  } else {
                    self.$router.push("/");
                  }
                });
              return;
            } else if (response.data.result == "ERR_REGISTERRED_EMAIL") {
              // ERR_REGISTERRED_EMAIL
              self.$swal.fire({
                text: self.$t("auth.error_registered_email"),
                icon: "error",
              });
              return;
            } else if (response.data.result == "ERR_WRONG_COUNTRY") {
              // 국가 상이함
              self.$swal.fire({
                text: self.$t("auth.error_wrong_country"),
                icon: "error",
              });
              return;
            }
          })
          .catch(function (error) {
            alert(error);
          })
          .finally(function () {
            self.process_queue.pop();
          });
      }); //-- googleTokenLogin
    }, //-- google_login

    login_success_callback: function (response) {
      let self = this;

      // SUCCESS
      let user_id = response.data.user_id;
      let user_email = response.data.user_email;
      let user_name = response.data.user_name;
      let user_country = response.data.user_country;
      let avatar_thumbnail = response.data.avatar_thumbnail;

      self.storage.set_user_info({
        user_id: user_id,
        user_email: user_email,
        user_name: user_name,
        user_country: user_country,
        avatar_thumbnail: avatar_thumbnail,
      });

      // 사용자 토큰 설정
      self.storage.set_user_token(response.data.token);

      // 장바구니 데이터 DB로 이동시키기
      self.add_user_cart_server().then(function () {
        // 장바구니 동기화
        self.common.sync_user_cart().then(function () {
          // 동의가 안되어 있으면 약관 동의로 이동
          if (
            response.data.agree_privacy != "YES" ||
            response.data.agree_term != "YES"
          ) {
            // 약관동의 페이지로 이동
            self.$router.push("/auth/agree");
          } else if (self.storage.get_user_avatar() == "") {
            // 아바타 설정이 안되어 있으면 설정 페이지로 이동
            self.$router.push("/avatar/customize");
          } else {
            // 되돌아갈 URL이 있다면...
            if (self.return_url != "") {
              location.href = self.return_url;
            } else {
              // 아니면 메인 페이지로 이동
              location.href = "/";
            }
          }
        });
      });
    }, //-- login_success_callback
  },
};
</script>

<style scoped>
.login > .section > button.google {
  background-color: #fff;
  background-image: url("../../assets/img/icon_google_logo.svg");
  background-repeat: no-repeat;
  background-position: left 1rem center;
  background-size: auto 50%;

  border: 1px solid #dedede;
}
.login > .section > button.google:hover {
  background-color: #dedede;
  color: #333;
}
</style>
